import { inject, Injectable } from '@angular/core';
import { FooterResponse } from 'core/capi/models/footer-response';
import { FooterService } from 'core/capi/services/footer.service';
import { FooterModel } from 'core/data/domain/footer.model';
import { FooterMapper } from 'core/data/repository/footer/footer.mapper';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FooterRepository {
  private readonly footerApi: FooterService = inject(FooterService);
  private readonly localization: LocalizationService = inject(LocalizationService);
  private readonly mapper: FooterMapper = inject(FooterMapper);

  public getListResponse(): Observable<FooterModel> {
    return this.localization.trackChanges(
      this.footerApi
        .getFooter({
          populate: {
            '0': 'copyright,menu,menu.items,menu,items.icon,payment,payment.items,payment.items.icon,certification,certification.items,certification.items.icon',
          },
        })
        .pipe(map((response: FooterResponse) => this.mapper.mapFromResponse(response))),
    );
  }
}
