import { inject, Injectable, Renderer2 } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { RendererService } from 'core/base/services/renderer.service';

@Injectable({
  providedIn: 'root',
})
export class GtmScriptService {
  private readonly window: Window = inject(WINDOW);
  private readonly renderer: Renderer2 = inject(RendererService).renderer;

  public create(options: { src: string }): HTMLScriptElement {
    const script = this.renderer.createElement('script');

    this.renderer.setAttribute(script, 'src', options.src);
    this.renderer.setProperty(script, 'async', true);

    return script;
  }

  public append(script: HTMLScriptElement): void {
    this.renderer.appendChild(this.window.document.head, script);
  }
}
