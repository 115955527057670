import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NavigationLinkItem } from 'core/base/model/navigation.model';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { TrackByPropPipe } from 'core/base/utils/track-by-prop.pipe';

@Component({
  selector: 'fid-footer-licenses',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, CdnUrlPipe, TrackByPropPipe],
  templateUrl: './footer-licenses.component.html',
  styleUrl: './footer-licenses.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLicensesComponent {
  @Input({ required: true }) public licenses!: NavigationLinkItem[];
}
