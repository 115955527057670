<fid-connected-casino></fid-connected-casino>

<header class="layout-header">
  <fid-header></fid-header>
</header>

<div class="layout-body">
  <main class="layout-content">
    <router-outlet></router-outlet>
  </main>

  <footer class="layout-footer">
    <fid-footer></fid-footer>
  </footer>
</div>
