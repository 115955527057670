import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';
import { LanguageSelectionComponent } from 'core/features/localization/components/language-selection/language-selection.component';
import { AuthenticatedDirective } from 'features/auth/directives/authenticated.directive';
import { NotAuthenticatedDirective } from 'features/auth/directives/not-authenticated.directive';
import { ProfileCardComponent } from 'features/profile/profile-card/profile-card.component';
import { UserDirective } from 'features/user/directives/user.directive';
import { AppLinkComponent } from 'shared/components/app-link/app-link.component';
import { OpenChatDirective } from 'shared/directives/open-chat.directive';

@Component({
  selector: 'fid-header-buttons',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    RouterLink,
    NgTemplateOutlet,
    LanguageSelectionComponent,
    TuiButtonModule,
    TuiSvgModule,
    TranslocoDirective,
    ProfileCardComponent,
    AuthenticatedDirective,
    NotAuthenticatedDirective,
    UserDirective,
    AppLinkComponent,
    OpenDialogDirective,
    OpenChatDirective,
  ],
  templateUrl: './header-buttons.component.html',
  styleUrl: './header-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderButtonsComponent {}
