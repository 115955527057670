import { inject, Injectable } from '@angular/core';
import { FooterResponse } from 'core/capi/models/footer-response';
import { ResponseMapper } from 'core/base/model/mapper.model';
import { NavigationLinkItem, NavigationLinksGroup } from 'core/base/model/navigation.model';
import { IconMapper } from 'core/data/common/icon/icon.mapper';
import { FooterModel } from 'core/data/domain/footer.model';

@Injectable({
  providedIn: 'root',
})
export class FooterMapper implements ResponseMapper<FooterResponse, FooterModel> {
  private readonly iconMapper: IconMapper = inject(IconMapper);

  public mapFromResponse({ data }: FooterResponse): FooterModel {
    return {
      navigation:
        data?.attributes?.menu?.map<NavigationLinksGroup>(menuItem => ({
          text: menuItem.title || '',
          navigationItems:
            menuItem.items?.map<NavigationLinkItem>(navItem => ({
              text: navItem.text || '',
              action: navItem.link || '',
              icon: this.iconMapper.mapFrom(navItem.icon),
            })) || [],
        })) || [],
      payments:
        data?.attributes?.payment?.items?.map(payment => ({
          icon: this.iconMapper.mapFrom(payment.icon),
          text: payment.text || '',
          action: payment.link || '',
        })) || [],
      licenses:
        data?.attributes?.certification?.items?.map<NavigationLinkItem>(license => ({
          text: license.text || '',
          action: license.link || '',
          icon: this.iconMapper.mapFrom(license.icon),
        })) || [],
      copyright: data?.attributes?.copyright || '',
    };
  }
}
