import { DestroyRef, inject } from '@angular/core';
import { LanguageInfo } from 'core/features/localization/model/localization.model';
import { LocalizationService } from 'core/features/localization/services/localization.service';
import {
  ACCEPT_LANGUAGE,
  DEFAULT_LANGUAGE_INFO,
  LANGUAGE_CODE_FROM_URL,
  LANGUAGE_CODE_FROM_URL_ASYNC,
  SAVED_LANGUAGE_CODE,
} from 'core/features/localization/tokens/localization.tokens';
import { map, Observable, skip, switchMap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const languageInitializationFactory = () => {
  const urlLangCode$: Observable<string> = inject(LANGUAGE_CODE_FROM_URL_ASYNC);
  const urlLangCode: string = inject(LANGUAGE_CODE_FROM_URL);
  const savedLangCode: string = inject(SAVED_LANGUAGE_CODE);
  const acceptLangCode: string | null | undefined = inject(ACCEPT_LANGUAGE, { optional: true });
  const defaultLang: LanguageInfo = inject(DEFAULT_LANGUAGE_INFO);
  const localization: LocalizationService = inject(LocalizationService);
  const destroyRef: DestroyRef = inject(DestroyRef);

  return (): Observable<boolean> =>
    localization.availableLanguages$.pipe(
      switchMap((langList: LanguageInfo[]) => {
        const defaultLangCode: string = (langList.find(lang => lang.isDefault) || defaultLang).code;
        const langCode: string | undefined =
          langList.find(
            (lang: LanguageInfo) =>
              lang.code === urlLangCode ||
              lang.code === savedLangCode ||
              lang.code === acceptLangCode,
          )?.code || defaultLangCode;

        urlLangCode$
          .pipe(
            skip(1),
            map(langCode => langList.find(lang => lang.code === langCode)?.code || defaultLangCode),
            takeUntilDestroyed(destroyRef),
          )
          .subscribe(langCode => {
            localization.setLanguage(langCode, true);
          });

        localization.setAvailableLanguages(langList);
        return localization.setLanguage(langCode, true);
      }),
    );
};
