<ng-container *ngIf="breakpoints$ | async as breakpoints">
  <ng-container *transloco="let t">
    <img
      *ngIf="projectStaticData.icon.url as url"
      class="user-card-image"
      [ngSrc]="url | cdnUrl"
      [alt]="projectStaticData.icon.alternativeText || ''"
      [width]="imageSizeMap[size]"
      [height]="imageSizeMap[size]"
    />

    <div class="user-card-details">
      <p
        class="user-card-text-tertiary user-card-text-blank"
        [ngClass]="{ _revert: !breakpoints.min1280 }"
      >
        {{ projectStaticData.name }} {{ t('wallet.balance') }}
      </p>
      <p class="user-card-text-primary">{{ user.balance | currency: user.currency }}</p>
    </div>

    <div *ngIf="breakpoints.min1280" class="user-card-extra">
      <button
        appearance="secondary"
        type="button"
        class="user-card-extra--btn _normal"
        [size]="'m'"
        [dialogConfig]="{ data: { casino: projectStaticData, wallet } }"
        tuiIconButton
        shape="rounded"
        fidOpenDialog="cashier"
      >
        <ng-container *ngTemplateOutlet="icon"></ng-container>
      </button>
      <button
        appearance="primary"
        type="button"
        class="user-card-extra--btn _hover"
        [size]="'m'"
        tuiIconButton
        shape="rounded"
      >
        <ng-container *ngTemplateOutlet="icon"></ng-container>
      </button>

      <ng-template #icon>
        <tui-svg src="fidPlus"></tui-svg>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
