import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiLinkModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiAccordionModule } from '@taiga-ui/kit';
import { RouterLinkDirective } from 'core/base/directives/router-link.directive';
import { NavigationLinksGroup } from 'core/base/model/navigation.model';
import { TrackByPropPipe } from 'core/base/utils/track-by-prop.pipe';
import { FidStartsWithPipe } from 'shared/pipes/startsWith.pipe';

@Component({
  selector: 'fid-footer-mobile-navigation',
  standalone: true,
  imports: [
    CommonModule,
    TuiAccordionModule,
    TuiLinkModule,
    TuiSvgModule,
    TranslocoDirective,
    TrackByPropPipe,
    RouterLinkDirective,
    FidStartsWithPipe,
  ],
  templateUrl: './footer-mobile-navigation.component.html',
  styleUrl: './footer-mobile-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterMobileNavigationComponent {
  @Input({ required: true }) public navigationLinksGroup!: NavigationLinksGroup[];
}
