import { ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import { ServicePageSlug } from 'core/data/domain/service-page.model';

export const servicePageGuard = (_: ActivatedRouteSnapshot, state: UrlSegment[]) => {
  const [, pageIdSegment] = state;

  return Object.values(ServicePageSlug).some(
    (value: ServicePageSlug): boolean => value === pageIdSegment.path,
  );
};
