import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiIconModule } from '@taiga-ui/experimental';
import { RouterLinkDirective } from 'core/base/directives/router-link.directive';
import { NavigationLinkItem } from 'core/base/model/navigation.model';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { TrackByPropPipe } from 'core/base/utils/track-by-prop.pipe';
import { AppUserService } from 'features/user/services/app-user.service';
import { AmpEventsName } from 'shared/services/amplitude.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

@Component({
  selector: 'fid-header-links',
  templateUrl: './header-links.component.html',
  styleUrl: './header-links.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    RouterLinkDirective,
    RouterLinkActive,
    TranslocoDirective,
    TuiButtonModule,
    CdnUrlPipe,
    TuiIconModule,
    TrackByPropPipe,
    TuiSvgModule,
    SendAnalyticsDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLinksComponent {
  private readonly appUserService: AppUserService = inject(AppUserService);

  @Input({ required: true }) public navigationLinks!: NavigationLinkItem[];

  @Output() public menuClosed: EventEmitter<void> = new EventEmitter();

  protected user$ = this.appUserService.user$;

  protected close(): void {
    this.menuClosed.emit();
  }

  protected readonly ampEventsName = AmpEventsName;
}
