export const ICONS_MAP: Record<string, string> = {
  fidDiscovery: 'discovery',
  fidCasino: 'casino',
  fidGames: 'games',
  fidOffers: 'offers',
  fidAppstore: 'appstore',
  fidGooglePlay: 'google-play',
  fidChat: 'chat',
  fidChevronLeft: 'chevron-left',
  tuiIconChevronLeft: 'chevron-left',
  fidChevronRight: 'chevron-right',
  tuiIconChevronRight: 'chevron-right',
  fidChevronDown: 'chevron-down',
  tuiIconChevronDownLarge: 'chevron-down',
  fidSearch: 'search',
  fidSearchBlue: 'search-blue',
  fidBurger: 'burger',
  fidLink: 'link',
  fidClose: 'close',
  tuiIconClose: 'close',
  fidPlay: 'play',
  fidDynamicPlay: 'dynamic-play',
  fidDynamicStop: 'dynamic-stop',
  fidCross: 'cross',
  fidQR: 'qr',
  fidSettings: 'settings',
  fidArrowDown: 'arrow-down',
  fidDiscoveryFill: 'discovery-fill',
  fidOffersAlternative: 'offers-alternative',
  fidOffersAlternativeFill: 'offers-alternative-fill',
  fidProfile: 'profile',
  fidProfileFill: 'profile-fill',
  fidWallet: 'wallet',
  fidWalletFill: 'wallet-fill',
  fidLogout: 'logout',
  fidDeposit: 'deposit',
  fidCheck: 'check',
  tuiIconCheck: 'check',
  tuiIconCheckLarge: 'check-large',
  fidCopy: 'copy',
  fidPlus: 'plus',
  fidMinus: 'minus',
  fidBars: 'bars',
  fidFunid: 'funid',
  fidFunidFill: 'funid-fill',
  fidFunidOutline: 'funid-outline',
  fidConnectFilled: 'connect-filled',
  fidCancel: 'cancel',
  fidClock: 'clock',
  fidCalendar: 'calendar',
  tuiIconCalendarLarge: 'calendar',
  fidIdentify: 'identify',
  fidDownloadApp: 'download-app',
  fidInfo: 'info',
  fidIconChevronRight: 'chevron-right',
  fidIconChevronLeft: 'chevron-left',
  fidEye: 'eye',
  fidEyeOpen: 'eye-open',
  fidDropdown: 'dropdown',
};
