import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { environment } from '@environments/environment';
import { provideTransloco } from '@ngneat/transloco';
import { TuiDialogModule, TuiRootModule, tuiSvgOptionsProvider } from '@taiga-ui/core';
import { ICONS_MAP } from 'core/base/constants/icons.constants';
import { markedOptionsFactory } from 'core/base/services/marked-options-factory';
import { ApiModule as FApiModule } from 'core/fapi/api.module';
import { provideLocalization } from 'core/features/localization/providers/localization.providers';
import { provideAppSearch } from 'core/features/search/providers/search.providers';
import { provideAuth } from 'features/auth/providers/auth.providers';
import { provideGtm } from 'features/gtm/providers/gtm.provider';
import { MARKED_OPTIONS, provideMarkdown } from 'ngx-markdown';
import { provideAppRouting } from './app.routes';
import { TranslocoHttpLoader } from './transloco-loader';
import { guestInterceptor } from 'features/guest/interceptors/guest.interceptor';
import { provideCustomValidationErrors } from 'core/base/providers/custom-validation-errors';
import { provideFirebase } from 'features/firebase/providers/firebase.provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideCustomValidationErrors(),
    provideGtm(),
    provideAnimations(),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideHttpClient(withInterceptors([guestInterceptor])),
    provideTransloco({
      config: {
        // TODO: Remove when content will add translations
        missingHandler: {
          logMissingKey: false,
        },
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    importProvidersFrom(
      TuiRootModule,
      TuiDialogModule,
      FApiModule.forRoot({ rootUrl: `https://${environment.fApiPath.replace(/\/api$/, '')}` }),
    ),
    provideAuth(),
    provideLocalization(),
    provideRouter([], withEnabledBlockingInitialNavigation()),
    provideAppRouting(),
    provideAppSearch(),
    tuiSvgOptionsProvider({
      path: (name: string) => {
        if (name.startsWith('fid') || (name.startsWith('tui') && ICONS_MAP[name])) {
          return `assets/icons/${ICONS_MAP[name]}.svg#${ICONS_MAP[name]}`;
        }

        return 'assets/flags/';
      },
    }),
    provideMarkdown({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      },
    }),
    provideFirebase(),
  ],
};
