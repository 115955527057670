import { AsyncPipe, NgIf, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { UserInfoResponse } from 'core/fapi/models';
import { ProfileAvatarComponent } from '../profile-avatar/profile-avatar.component';

@Component({
  selector: 'fid-profile-card',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    CdnUrlPipe,
    CdnUrlPipe,
    NgOptimizedImage,
    TranslocoDirective,
    ProfileAvatarComponent,
  ],
  templateUrl: './profile-card.component.html',
  styleUrl: './profile-card.component.scss',
})
export class ProfileCardComponent {
  @Input({ required: true }) public user!: UserInfoResponse;
}
