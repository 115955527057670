import { ViewportScroller } from '@angular/common';
import { DestroyRef, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FidQueryParam } from 'core/base/constants/query-params.constants';
import { filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const scrollStrategy = () => {
  const router: Router = inject(Router);
  const viewportScroller: ViewportScroller = inject(ViewportScroller);
  const activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  const destroyRef: DestroyRef = inject(DestroyRef);

  return (): void => {
    router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        filter(
          () =>
            ![FidQueryParam.Start, FidQueryParam.Limit]
              .map(param => activatedRoute.snapshot.queryParamMap.get(param))
              .filter(Boolean).length,
        ),
        filter(() => !router.url.includes('dialog')),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe(() => viewportScroller.scrollToPosition([0, 0]));
  };
};
