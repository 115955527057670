import { AsyncPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { NavigationLinkItem } from 'core/base/model/navigation.model';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { TrackByPropPipe } from 'core/base/utils/track-by-prop.pipe';
import { BreakpointsService } from 'shared/services/breakpoints.service';

@Component({
  selector: 'fid-footer-payments',
  standalone: true,
  imports: [
    TuiButtonModule,
    TranslocoDirective,
    NgForOf,
    NgOptimizedImage,
    TuiSvgModule,
    NgIf,
    AsyncPipe,
    CdnUrlPipe,
    TrackByPropPipe,
  ],
  templateUrl: './footer-payments.component.html',
  styleUrl: './footer-payments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterPaymentsComponent {
  @Input({ required: true }) public paymentsList!: NavigationLinkItem[];

  private readonly breakpoints = inject(BreakpointsService);

  protected readonly breakpoints$ = this.breakpoints.selectAllBreakpointsObservable();
}
