import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule } from '@taiga-ui/core';
import { NavigationLinkItem } from 'core/base/model/navigation.model';
import { SidebarRepository } from 'core/data/repository/sidebar/sidebar.repository';
import { HeaderButtonsComponent } from 'layout/header/components/header-buttons/header-buttons.component';
import { HeaderLinksComponent } from 'layout/header/components/header-links/header-links.component';
import { HeaderLogoComponent } from 'layout/header/components/header-logo/header-logo.component';
import { Observable } from 'rxjs';
import { HeaderLoggedDashboardComponent } from '../header-logged-dashboard/header-logged-dashboard.component';
import { NotAuthenticatedDirective } from 'features/auth/directives/not-authenticated.directive';
import { AuthenticatedDirective } from 'features/auth/directives/authenticated.directive';
import { ObserveCTAViewportPresenceDirective } from 'shared/directives/observe-cta-viewport-presence.directive';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';

@Component({
  selector: 'fid-header-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    TuiButtonModule,
    HeaderButtonsComponent,
    HeaderLinksComponent,
    HeaderLogoComponent,
    TranslocoDirective,
    HeaderLoggedDashboardComponent,
    ObserveCTAViewportPresenceDirective,
    OpenDialogDirective,
    AuthenticatedDirective,
    NotAuthenticatedDirective,
  ],
  templateUrl: './header-sidebar.component.html',
  styleUrl: './header-sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSidebarComponent {
  private readonly sidebar: SidebarRepository = inject(SidebarRepository);

  protected readonly navigationLinks$: Observable<NavigationLinkItem[]> =
    this.sidebar.getListResponse();

  @Output() public menuClosed: EventEmitter<void> = new EventEmitter();

  protected close(): void {
    this.menuClosed.emit();
  }
}
