import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { AuthStorageService } from 'features/auth/services/auth-storage.service';

export const authGuard: CanMatchFn = (): boolean | Promise<boolean> => {
  const storage: AuthStorageService = inject(AuthStorageService);
  const router: Router = inject(Router);

  if (storage.hasAuthToken()) {
    return true;
  } else {
    return router.navigate(['/']);
  }
};
