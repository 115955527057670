import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppLinkComponent } from 'shared/components/app-link/app-link.component';
import { LanguageSelectionComponent } from 'core/features/localization/components/language-selection/language-selection.component';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { OpenChatDirective } from 'shared/directives/open-chat.directive';

@Component({
  selector: 'fid-footer-buttons',
  standalone: true,
  imports: [
    AppLinkComponent,
    LanguageSelectionComponent,
    TuiButtonModule,
    TranslocoDirective,
    TuiSvgModule,
    OpenChatDirective,
  ],
  templateUrl: './footer-buttons.component.html',
  styleUrl: './footer-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterButtonsComponent {}
