import { CommonModule } from '@angular/common';
import { afterNextRender, ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { GuestService } from 'features/guest/services/guest.service';
import { TuiAlertModule, TuiRootModule } from '@taiga-ui/core';
import { SocketIoService } from 'core/base/services/websocket.service';
import { AmplitudeService } from 'shared/services/amplitude.service';

@Component({
  selector: 'fid-root',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, CommonModule, TuiRootModule, TuiAlertModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private readonly guestService: GuestService = inject(GuestService);
  private readonly wsIo: SocketIoService = inject(SocketIoService);
  private readonly amplitude = inject(AmplitudeService);

  constructor() {
    afterNextRender(() => this.wsIo.connect());
  }

  public ngOnInit(): void {
    this.amplitude.init();
    this.guestService.register();
  }
}
