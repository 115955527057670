import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { inject, Provider } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ValidationErrorsText } from 'core/base/constants/form-validators.constants';

export function validationErrorsFactory() {
  const transloco = inject(TranslocoService);

  return {
    required: transloco.translate(ValidationErrorsText.Required),
    requiredTrue: transloco.translate(ValidationErrorsText.Required),
    minlength: ({ requiredLength }: { requiredLength: string }) =>
      `${transloco.translate(ValidationErrorsText.MinLength)} — ${requiredLength}`,
    maxlength: ({ requiredLength }: { requiredLength: string }) =>
      `${transloco.translate(ValidationErrorsText.MaxLength)} — ${requiredLength}`,
  };
}

export const provideCustomValidationErrors = (): Provider => {
  return {
    provide: TUI_VALIDATION_ERRORS,
    useFactory: validationErrorsFactory,
  };
};
