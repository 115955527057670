<ng-container *transloco="let t">
  <ng-container *fidAuthenticated #userDir="user" fidUser>
    <div *ngIf="userDir.user$ | async as user" class="header-buttons">
      <fid-profile-card
        role="link"
        class="header-button _grow"
        [routerLink]="['profile']"
        [user]="user"
      ></fid-profile-card>

      <button
        appearance="tertiary-flat"
        type="button"
        class="side-navigation-button"
        [attr.aria-label]="t('global.btn.openChat')"
        tuiIconButton
        fidOpenChat
      >
        <tui-svg [src]="'fidChat'"></tui-svg>
      </button>
    </div>
  </ng-container>

  <ng-container *fidNotAuthenticated>
    <ng-container *ngTemplateOutlet="unauthenticated"></ng-container>
  </ng-container>

  <ng-template #unauthenticated>
    <fid-app-link></fid-app-link>

    <div class="header-buttons">
      <button
        appearance="tertiary-flat"
        type="button"
        class="side-navigation-button header-button _grow"
        [attr.aria-label]="t('global.btn.signIn')"
        tuiButton
        fidOpenDialog="login"
      >
        {{ t('global.btn.signIn') }}
      </button>

      <button
        appearance="tertiary-flat"
        type="button"
        class="side-navigation-button"
        [attr.aria-label]="t('global.btn.openChat')"
        tuiIconButton
        fidOpenChat
      >
        <tui-svg [src]="'fidChat'"></tui-svg>
      </button>

      <fid-language-selection
        appearance="tertiary-flat"
        class="side-navigation-button"
      ></fid-language-selection>
    </div>
  </ng-template>
</ng-container>
