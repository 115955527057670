<div class="sidebar-header">
  <ng-container *fidAuthenticated>
    <fid-header-logged-dashboard></fid-header-logged-dashboard>
  </ng-container>

  <ng-container *fidNotAuthenticated>
    <fid-header-logo></fid-header-logo>

    <button
      *transloco="let t"
      #ctaViewportPresenceRef="ctaViewportPresence"
      type="button"
      [appearance]="ctaViewportPresenceRef.isAnyVisible() ? 'outline' : 'secondary'"
      [size]="'l'"
      tuiButton
      fidOpenDialog="login"
      fidObserveCTAViewportPresence
    >
      {{ t('global.btn.createWallet') }}
    </button>
  </ng-container>
</div>

<fid-header-links
  *ngIf="navigationLinks$ | async as navigationLinks"
  [navigationLinks]="navigationLinks"
  (menuClosed)="close()"
></fid-header-links>

<div class="sidebar-footer">
  <fid-header-buttons></fid-header-buttons>
</div>
