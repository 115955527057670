<ng-container *transloco="let t">
  <nav class="mobile-navigation">
    <ng-container *fidAuthenticated>
      <ul class="mobile-navigation--list">
        <li class="mobile-navigation--item">
          <a
            class="mobile-navigation--link"
            [routerLinkActive]="'_active'"
            [fidSendAnalytics]="[ampEventsName.BottomBarClick, { source: 'discovery' }]"
            fidRouterLink="/discovery"
          >
            <span class="mobile-navigation--icon-wrapper">
              <tui-svg class="mobile-navigation--icon" src="fidDiscovery" />
              <tui-svg
                class="mobile-navigation--icon _hover-icon"
                [src]="'fidDiscovery' + 'Fill'"
              />
            </span>
            <span class="mobile-navigation--name">
              {{ t('global.btn.discovery') }}
            </span>
          </a>
        </li>

        <li class="mobile-navigation--item">
          <a
            class="mobile-navigation--link"
            [routerLinkActive]="'_active'"
            [fidSendAnalytics]="[ampEventsName.BottomBarClick, { source: 'wallet' }]"
            fidRouterLink="/wallet"
          >
            <span class="mobile-navigation--icon-wrapper">
              <tui-svg class="mobile-navigation--icon" src="fidOffersAlternative" />
              <tui-svg
                class="mobile-navigation--icon _hover-icon"
                [src]="'fidOffersAlternative' + 'Fill'"
              />
            </span>
            <span class="mobile-navigation--name">
              {{ t('global.btn.wallet') }}
            </span>
          </a>
        </li>

        <li class="mobile-navigation--item _visible">
          <a
            class="mobile-navigation--link _centered"
            [routerLinkActive]="'_active'"
            [fidSendAnalytics]="[ampEventsName.BottomBarClick, { source: 'games' }]"
            fidRouterLink="/games"
          >
            <span class="mobile-navigation--icon-wrapper">
              <fid-animation
                class="mobile-navigation--icon"
                status="playing"
                url="/assets/animation/play.lottie"
                width="84px"
                height="84px"
              ></fid-animation>
              <fid-animation
                class="mobile-navigation--icon _hover-icon"
                status="playing"
                url="/assets/animation/play-colored.lottie"
                width="84px"
                height="84px"
              ></fid-animation>
            </span>
          </a>
        </li>

        <li class="mobile-navigation--item">
          <a
            class="mobile-navigation--link"
            [routerLinkActive]="'_active'"
            [fidSendAnalytics]="[ampEventsName.BottomBarClick, { source: 'offers' }]"
            fidRouterLink="/offers"
          >
            <span class="mobile-navigation--icon-wrapper">
              <tui-svg class="mobile-navigation--icon" src="fidWallet" />
              <tui-svg class="mobile-navigation--icon _hover-icon" [src]="'fidWallet' + 'Fill'" />
            </span>
            <span class="mobile-navigation--name">
              {{ t('global.btn.offers') }}
            </span>
          </a>
        </li>

        <li #userDir="user" class="mobile-navigation--item" fidUser>
          <a
            class="mobile-navigation--link"
            [routerLinkActive]="'_active'"
            [fidSendAnalytics]="[ampEventsName.BottomBarClick, { source: 'profile' }]"
            fidRouterLink="/profile"
          >
            <ng-container *ngIf="userDir.user$ | async as user">
              <fid-profile-avatar
                class="mobile-navigation--icon-wrapper"
                [code]="user.avatar"
                [withBackground]="true"
                radius="full"
                size="auto"
              ></fid-profile-avatar>
            </ng-container>
            <span class="mobile-navigation--name">
              {{ t('global.btn.profile') }}
            </span>
          </a>
        </li>
      </ul>
    </ng-container>

    <ng-container *fidNotAuthenticated>
      <ul class="mobile-navigation--list">
        <li class="mobile-navigation--item">
          <a
            class="mobile-navigation--link"
            [routerLinkActive]="'_active'"
            [fidSendAnalytics]="[ampEventsName.BottomBarClick, { source: 'discovery' }]"
            fidRouterLink="/discovery"
          >
            <span class="mobile-navigation--icon-wrapper">
              <tui-svg class="mobile-navigation--icon" src="fidDiscovery" />
              <tui-svg
                class="mobile-navigation--icon _hover-icon"
                [src]="'fidDiscovery' + 'Fill'"
              />
            </span>
            <span class="mobile-navigation--name">
              {{ t('global.btn.discovery') }}
            </span>
          </a>
        </li>

        <li class="mobile-navigation--item">
          <button
            type="button"
            class="mobile-navigation--link"
            [fidSendAnalytics]="[ampEventsName.BottomBarClick, { source: 'wallet' }]"
            fidOpenDialog="login"
          >
            <span class="mobile-navigation--icon-wrapper">
              <tui-svg class="mobile-navigation--icon" src="fidOffersAlternative" />
              <tui-svg
                class="mobile-navigation--icon _hover-icon"
                [src]="'fidOffersAlternative' + 'Fill'"
              />
            </span>
            <span class="mobile-navigation--name">{{ t('global.btn.wallet') }}</span>
          </button>
        </li>

        <li class="mobile-navigation--item _visible">
          <a
            class="mobile-navigation--link _centered"
            [routerLinkActive]="'_active'"
            [fidSendAnalytics]="[ampEventsName.BottomBarClick, { source: 'games' }]"
            fidRouterLink="/games"
          >
            <span class="mobile-navigation--icon-wrapper">
              <fid-animation
                class="mobile-navigation--icon"
                status="playing"
                url="/assets/animation/play.lottie"
                width="84px"
                height="84px"
              ></fid-animation>
              <fid-animation
                class="mobile-navigation--icon _hover-icon"
                status="playing"
                url="/assets/animation/play-colored.lottie"
                width="84px"
                height="84px"
              ></fid-animation>
            </span>
          </a>
        </li>

        <li class="mobile-navigation--item">
          <a
            class="mobile-navigation--link"
            [routerLinkActive]="'_active'"
            [fidSendAnalytics]="[ampEventsName.BottomBarClick, { source: 'offers' }]"
            fidRouterLink="/offers"
          >
            <span class="mobile-navigation--icon-wrapper">
              <tui-svg class="mobile-navigation--icon" src="fidWallet" />
              <tui-svg class="mobile-navigation--icon _hover-icon" [src]="'fidWallet' + 'Fill'" />
            </span>
            <span class="mobile-navigation--name">
              {{ t('global.btn.offers') }}
            </span>
          </a>
        </li>

        <li class="mobile-navigation--item">
          <button
            type="button"
            class="mobile-navigation--link"
            [fidSendAnalytics]="[ampEventsName.BottomBarClick, { source: 'profile' }]"
            fidOpenDialog="login"
          >
            <span class="mobile-navigation--icon-wrapper">
              <tui-svg class="mobile-navigation--icon" src="fidProfile" />
              <tui-svg class="mobile-navigation--icon _hover-icon" [src]="'fidProfile' + 'Fill'" />
            </span>
            <span class="mobile-navigation--name">{{ t('global.btn.profile') }}</span>
          </button>
        </li>
      </ul>
    </ng-container>
  </nav>
</ng-container>
