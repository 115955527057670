import { APP_INITIALIZER, EnvironmentProviders, Provider } from '@angular/core';
import { languageInitializationFactory } from 'core/features/localization/factories/language-initialization.factory';
import { LanguageValidation } from 'core/features/localization/model/localization.model';
import { LanguageValidationService } from 'core/features/localization/services/language-validation.service';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { apiInterceptor } from 'core/features/localization/interceptors/api.interceptor';

export const provideLocalization = (): Array<Provider | EnvironmentProviders> => [
  {
    provide: LanguageValidation,
    useClass: LanguageValidationService,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: languageInitializationFactory,
    multi: true,
  },
  provideHttpClient(withInterceptors([apiInterceptor])),
];
