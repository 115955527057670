import { ProfileIntegrationDataPayloadRequest } from 'core/fapi/models/profile-integration-data-payload-request';

export enum Integration {
  Appsflyer = 'appsflyer',
}

export interface GuestParams {
  payload: ProfileIntegrationDataPayloadRequest;
  uuid: string | undefined;
}
