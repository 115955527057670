<ng-container *transloco="let t">
  <button
    appearance="tertiary-flat"
    type="button"
    class="side-navigation-button"
    [attr.aria-label]="t('global.btn.openChat')"
    tuiIconButton
    fidOpenChat
  >
    <tui-svg [src]="'fidChat'"></tui-svg>
  </button>

  <fid-language-selection
    appearance="tertiary-flat"
    class="side-navigation-button"
  ></fid-language-selection>

  <fid-app-link class="footer-app-link"></fid-app-link>
</ng-container>
