import { DestroyRef, Directive, inject } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { distinctUntilChanged } from 'rxjs';
import { CTARegistryService } from 'shared/services/cta-registry.service';

@Directive({
  selector: '[fidObserveCTAViewportPresence]',
  standalone: true,
  exportAs: 'ctaViewportPresence',
})
export class ObserveCTAViewportPresenceDirective {
  private readonly ctaRegistryService = inject(CTARegistryService);

  private readonly destroyRef = inject(DestroyRef);

  public readonly isAnyVisible = toSignal(
    this.ctaRegistryService
      .observeVisibility()
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef)),
  );
}
