import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { DestroyRef, inject } from '@angular/core';
import { ApiConfiguration } from 'core/fapi/api-configuration';
import { ResponseErrorCode } from 'features/auth/model/auth-error-codes.model';
import { AppAuthService } from 'features/auth/services/app-auth.service';
import { AuthStorageService } from 'features/auth/services/auth-storage.service';
import { BehaviorSubject, catchError, NEVER, Observable, switchMap, take, throwError } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';

const isRefreshingToken = new BehaviorSubject<boolean>(false);
const refreshTokenResponse$ = new BehaviorSubject<boolean | null>(null);

export function unauthorizedErrorInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const refreshToken: string | null = inject(AuthStorageService).getRefreshToken();
  const appAuth: AppAuthService = inject(AppAuthService);
  const apiConfiguration: ApiConfiguration = inject(ApiConfiguration);
  const isApiRequest: boolean = req.url.includes(apiConfiguration.rootUrl);
  const destroyRef: DestroyRef = inject(DestroyRef);

  return isApiRequest
    ? next(req).pipe(
        catchError(response => {
          if (response.status === ResponseErrorCode.Unauthorized) {
            if (response.url?.includes('auth/refresh')) {
              isRefreshingToken.next(false);
              refreshTokenResponse$.next(false);
              appAuth.logout();
              return NEVER;
            }

            if (isRefreshingToken.value) {
              return refreshTokenResponse$.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(success => (success ? next(req) : throwError(() => response))),
              );
            }

            if (refreshToken) {
              isRefreshingToken.next(true);

              return appAuth.refreshAuthToken(refreshToken).pipe(
                take(1),
                switchMap(() => {
                  isRefreshingToken.next(false);
                  refreshTokenResponse$.next(true);
                  return next(req);
                }),
                takeUntilDestroyed(destroyRef),
                catchError(() => {
                  isRefreshingToken.next(false);
                  refreshTokenResponse$.next(false);
                  appAuth.logout();
                  return NEVER;
                }),
              );
            }

            return NEVER;
          }

          return throwError(() => response);
        }),
      )
    : next(req);
}
