import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiSvgModule } from '@taiga-ui/core';
import { AnimationComponent } from 'core/animation/animation.component';
import { RouterLinkDirective } from 'core/base/directives/router-link.directive';
import { AuthenticatedDirective } from 'features/auth/directives/authenticated.directive';
import { NotAuthenticatedDirective } from 'features/auth/directives/not-authenticated.directive';
import { ProfileAvatarComponent } from 'features/profile/profile-avatar/profile-avatar.component';
import { UserDirective } from 'features/user/directives/user.directive';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';
import { AmpEventsName } from 'shared/services/amplitude.service';

@Component({
  selector: 'fid-bottom-bar',
  standalone: true,
  imports: [
    TranslocoDirective,
    RouterLinkDirective,
    AsyncPipe,
    NgIf,
    RouterLinkActive,
    TuiSvgModule,
    AnimationComponent,
    ProfileAvatarComponent,
    UserDirective,
    AuthenticatedDirective,
    NotAuthenticatedDirective,
    OpenDialogDirective,
    SendAnalyticsDirective,
  ],
  templateUrl: './bottom-bar.component.html',
  styleUrl: './bottom-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomBarComponent {
  protected readonly ampEventsName = AmpEventsName;
}
