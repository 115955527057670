import { inject, Injectable, isDevMode } from '@angular/core';
import { FirebaseApp, initializeApp } from '@firebase/app';
import { Analytics, getAnalytics } from '@firebase/analytics';
import { IS_BROWSER_PLATFORM } from 'core/base/tokens/web-apis.token';
import { environment as devEnvironment } from '@environments/environment.development';
import { environment as prodEnvironment } from '@environments/environment.devprod';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private readonly isBrowserPlatform: boolean = inject(IS_BROWSER_PLATFORM);

  private app?: FirebaseApp;
  private analytics?: Analytics;
  private firebaseConfig = isDevMode() ? devEnvironment : prodEnvironment;

  constructor() {
    if (this.isBrowserPlatform) {
      this.app = initializeApp({ ...this.firebaseConfig.firebaseConfig });
      this.analytics = getAnalytics(this.app);
    }
  }
}
