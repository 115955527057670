/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiService } from './services/api.service';
import { UserService } from './services/user.service';
import { ProjectService } from './services/project.service';
import { TransactionService } from './services/transaction.service';
import { PaymentService } from './services/payment.service';
import { HealthService } from './services/health.service';
import { AuthService } from './services/auth.service';
import { Service } from './services/service';
import { RewardService } from './services/reward.service';
import { WalletService } from './services/wallet.service';
import { SwiperService } from './services/swiper.service';
import { SupportService } from './services/support.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiService,
    UserService,
    ProjectService,
    TransactionService,
    PaymentService,
    HealthService,
    AuthService,
    Service,
    RewardService,
    WalletService,
    SwiperService,
    SupportService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
