<div *ngIf="footer$ | async as footer" class="container footer">
  <fid-footer-mobile-navigation
    class="footer--mobile-navigation"
    [navigationLinksGroup]="footer.navigation"
  ></fid-footer-mobile-navigation>

  <fid-footer-desktop-navigation
    class="footer--desktop-navigation"
    [navigationLinksGroup]="footer.navigation"
  ></fid-footer-desktop-navigation>

  <div class="footer--bottom">
    <fid-footer-payments
      class="footer--payments"
      [paymentsList]="footer.payments"
    ></fid-footer-payments>

    <fid-footer-licenses
      class="footer--licenses"
      [licenses]="footer.licenses"
    ></fid-footer-licenses>

    <fid-footer-buttons class="footer--buttons"></fid-footer-buttons>
  </div>

  <markdown class="footer--copyright markdown" [data]="footer.copyright"></markdown>
</div>
