import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { Route, ROUTES, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { provideTranslocoScope } from '@ngneat/transloco';
import { DialogComponentName } from 'core/features/dialog/constants/dialogs-list.constants';
import { LanguageValidation } from 'core/features/localization/model/localization.model';
import { SAVED_LANGUAGE_CODE } from 'core/features/localization/tokens/localization.tokens';
import { authGuard } from 'features/auth/guards/auth.guard';
import { servicePageGuard } from 'features/service-pages/service-page.guard';
import { LayoutComponent } from 'layout/layout/layout.component';
import { scrollStrategy } from 'core/base/configs/scroll-strategy.config';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'discovery',
    pathMatch: 'full',
  },
  {
    path: 'discovery',
    loadChildren: () => import('features/discovery/discovery.routes').then(m => m.discoveryRoutes),
    providers: [provideTranslocoScope('discovery')],
  },
  {
    path: 'casinos',
    loadChildren: () => import('features/casino/casino.routes').then(m => m.casinoRoutes),
    providers: [provideTranslocoScope('casino')],
  },
  {
    path: 'games',
    loadChildren: () => import('features/games/games.routes').then(m => m.gamesRoutes),
    providers: [provideTranslocoScope('games')],
  },
  {
    path: 'offers',
    loadChildren: () => import('features/funid-offers/offers.routes').then(m => m.offersRoutes),
    providers: [provideTranslocoScope('offers'), provideTranslocoScope('promotions')],
  },
  {
    path: 'promotions',
    loadChildren: () =>
      import('features/promotions/promotions.routes').then(m => m.promotionsRoutes),
    providers: [provideTranslocoScope('offers'), provideTranslocoScope('promotions')],
  },
  {
    path: 'info/:id',
    canMatch: [servicePageGuard],
    loadComponent: () =>
      import('features/service-pages/service-page/service-page.component').then(
        m => m.ServicePageComponent,
      ),
  },
  {
    path: 'profile',
    loadChildren: () => import('features/profile/profile.routes').then(m => m.profileRoutes),
    providers: [provideTranslocoScope('profile'), provideTranslocoScope('dialog')],
    canMatch: [authGuard],
  },
  {
    path: 'wallet',
    loadChildren: () => import('features/wallet/wallet.routes').then(m => m.walletRoutes),
    providers: [provideTranslocoScope('wallet')],
    canMatch: [authGuard],
  },
  {
    path: 'dev-ui',
    loadChildren: () => import('features/dev-ui/dev-ui.routes').then(m => m.devUiRoutes),
    providers: [provideTranslocoScope('dev-ui')],
  },
  {
    path: '404',
    loadComponent: () =>
      import('features/not-found/not-found.component').then(m => m.NotFoundComponent),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

const dialogRoutes: Routes = [
  {
    path: ':',
    loadComponent: () =>
      import('core/features/dialog/dialog-wrapper/dialog-wrapper.component').then(
        m => m.DialogWrapperComponent,
      ),
    outlet: 'dialog',
    providers: [provideTranslocoScope('dialog')],
    children: [
      {
        path: DialogComponentName.LanguageSelection,
        loadComponent: () =>
          import(
            'core/features/localization/components/language-selection-dialog/language-selection-dialog.component'
          ).then(m => m.LanguageSelectionDialogComponent),
      },
      {
        path: DialogComponentName.ProjectInfo,
        loadComponent: () =>
          import('features/auth/dialogs/project-info-dialog/project-info-dialog.component').then(
            m => m.ProjectInfoDialogComponent,
          ),
      },
      {
        path: DialogComponentName.Casinos,
        loadComponent: () =>
          import('features/casino/casinos-dialog/casinos-dialog.component').then(
            m => m.CasinosDialogComponent,
          ),
      },
      {
        path: DialogComponentName.GameProviders,
        loadComponent: () =>
          import('features/games/game-providers-dialog/game-providers-dialog.component').then(
            m => m.GameProvidersDialogComponent,
          ),
      },
      {
        path: `${DialogComponentName.HowItWorks}/:id`,
        loadComponent: () =>
          import('features/how-it-works/how-it-works-dialog/how-it-works-dialog.component').then(
            m => m.HowItWorksDialogComponent,
          ),
      },
      {
        path: `${DialogComponentName.Transaction}/:id`,
        loadComponent: () =>
          import(
            'features/transactions/components/transaction-dialog/transaction-dialog.component'
          ).then(m => m.TransactionDialogComponent),
      },
      {
        path: DialogComponentName.Login,
        loadComponent: () =>
          import('features/auth/dialogs/login-dialog/login-dialog.component').then(
            m => m.LoginDialogComponent,
          ),
      },
      {
        path: DialogComponentName.Registration,
        loadComponent: () =>
          import('features/auth/dialogs/registration-dialog/registration-dialog.component').then(
            m => m.RegistrationDialogComponent,
          ),
      },
      {
        path: DialogComponentName.BrandLogin,
        loadComponent: () =>
          import(
            'features/project/dialogs/project-login-dialog/project-login-dialog.component'
          ).then(m => m.ProjectLoginDialogComponent),
      },
      {
        path: DialogComponentName.Cashier,
        loadComponent: () =>
          import('features/cashier/dialogs/cashier-dialog/cashier-dialog.component').then(
            m => m.CashierDialogComponent,
          ),
      },
      {
        path: DialogComponentName.ChooseProject,
        loadComponent: () =>
          import(
            'features/project/dialogs/project-choose-dialog/project-choose-dialog.component'
          ).then(m => m.ProjectChooseDialogComponent),
      },
    ],
  },
];

const authDialogRoute: Route = {
  path: 'funid-auth',
  loadComponent: () =>
    import('features/auth/dialogs/login-qr-dialog/login-qr-dialog.component').then(
      m => m.LoginQrDialogComponent,
    ),
  outlet: 'lib-dialog',
  providers: [provideTranslocoScope('dialog')],
};

const routerFactory = () => {
  const savedLang: string = inject(SAVED_LANGUAGE_CODE);
  const langValidation: LanguageValidation = inject(LanguageValidation);

  const appRouting: Routes = [
    {
      matcher: (segments: UrlSegment[]): UrlMatchResult | null => {
        if (!langValidation.isValid(segments?.[0]?.path)) {
          const defaultLangSegment: UrlSegment = new UrlSegment(savedLang, {});

          return {
            consumed: [defaultLangSegment],
            posParams: {
              lang: defaultLangSegment,
            },
          };
        }

        const langSegment: UrlSegment = segments[0];

        return {
          consumed: [langSegment],
          posParams: {
            lang: langSegment,
          },
        };
      },
      component: LayoutComponent,
      children: routes,
      providers: [provideTranslocoScope('global')],
    },
    authDialogRoute,
    ...dialogRoutes,
  ];

  return appRouting;
};

export const provideAppRouting = (): Provider[] => [
  {
    provide: ROUTES,
    useFactory: routerFactory,
    multi: true,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: scrollStrategy,
    multi: true,
  },
];
