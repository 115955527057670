import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FooterModel } from 'core/data/domain/footer.model';
import { FooterRepository } from 'core/data/repository/footer/footer.repository';
import { Observable } from 'rxjs';
import { AsyncPipe, NgIf } from '@angular/common';
import { FooterMobileNavigationComponent } from 'layout/footer/components/footer-mobile-navigation/footer-mobile-navigation.component';
import { FooterDesktopNavigationComponent } from 'layout/footer/components/footer-desktop-navigation/footer-desktop-navigation.component';
import { FooterPaymentsComponent } from 'layout/footer/components/footer-payments/footer-payments.component';
import { FooterLicensesComponent } from 'layout/footer/components/footer-licenses/footer-licenses.component';
import { FooterButtonsComponent } from 'layout/footer/components/footer-buttons/footer-buttons.component';
import { MarkdownComponent } from 'ngx-markdown';

@Component({
  selector: 'fid-footer',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    FooterMobileNavigationComponent,
    FooterDesktopNavigationComponent,
    FooterPaymentsComponent,
    FooterLicensesComponent,
    FooterButtonsComponent,
    MarkdownComponent,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  private readonly footer: FooterRepository = inject(FooterRepository);

  protected readonly footer$: Observable<FooterModel> = this.footer.getListResponse();
}
