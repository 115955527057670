import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConnectedCasinoComponent } from 'features/casino/connected-casino/connected-casino.component';
import { FooterComponent } from 'layout/footer/footer.component';
import { HeaderComponent } from 'layout/header/header.component';

@Component({
  selector: 'fid-layout',
  standalone: true,
  imports: [CommonModule, RouterOutlet, FooterComponent, HeaderComponent, ConnectedCasinoComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {}
