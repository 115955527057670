import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ApiConfiguration } from 'core/fapi/api-configuration';
import { AuthStorageService } from 'features/auth/services/auth-storage.service';
import { Observable } from 'rxjs';
import { authControllerRegistration } from 'core/fapi/fn/auth/auth-controller-registration';
import { AuthTempHolderService } from 'features/auth/services/auth-temp-storage.service';

// add Authorisation token to requests
export function authInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const authTempHolder: AuthTempHolderService = inject(AuthTempHolderService);
  const storage: AuthStorageService = inject(AuthStorageService);
  const apiConfiguration: ApiConfiguration = inject(ApiConfiguration);
  const isApiRequest: boolean = req.url.includes(apiConfiguration.rootUrl);

  const tempToken = authTempHolder.get()?.accessToken;
  const isRegistration = req.url.includes(authControllerRegistration.PATH);

  const request =
    (tempToken && isRegistration) || (storage.hasAuthToken() && isApiRequest)
      ? req.clone({
          headers: req.headers.set(
            'Authorization',
            `Bearer ${isRegistration ? tempToken : storage.getAuthToken()}`,
          ),
        })
      : req;

  return next(request);
}
