import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'fidStartsWith',
})
export class FidStartsWithPipe implements PipeTransform {
  public transform(value: string, key: string): boolean {
    return value.startsWith(key);
  }
}
