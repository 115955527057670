import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { RouterLinkDirective } from 'core/base/directives/router-link.directive';
import { QrImageComponent } from 'shared/components/qr-image/qr-image.component';
import { BreakpointsService } from 'shared/services/breakpoints.service';
import { XmasBackgroundComponent } from 'shared/components/xmas-background/xmas-background.component';

@Component({
  selector: 'fid-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrl: './header-logo.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    TuiButtonModule,
    TuiSvgModule,
    NgOptimizedImage,
    QrImageComponent,
    RouterLinkDirective,
    XmasBackgroundComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLogoComponent {
  private readonly breakpointsService = inject(BreakpointsService);

  protected readonly breakpoints$ = this.breakpointsService.selectAllBreakpointsObservable();

  @HostBinding('class.is-flipped')
  public isFlipped: boolean = false;

  protected flip(): void {
    this.isFlipped = !this.isFlipped;
  }
}
