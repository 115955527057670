<ng-container *transloco="let t">
  <div class="container hidden-scrollbar connected-casino-list">
    <ng-container *fidAuthenticated>
      <ng-container *ngIf="user$ | async as user">
        <fid-user-card
          class="connected-casino-item"
          [user]="user"
          [dialogConfig]="{ data: { casino: projectStaticData, wallet } }"
          [fidSendAnalytics]="[ampEventsName.FunIdBalanceItemClick]"
          fidOpenDialog="cashier"
        ></fid-user-card>
      </ng-container>

      <ng-container *ngFor="let casino of connected$ | async; trackBy: 'name' | trackByProp">
        <fid-connect-new-card
          *ngIf="casino.name === 'addNew'; else casinoCard"
          class="connected-casino-item"
          [fidRouterLink]="['casinos']"
          [fidSendAnalytics]="[ampEventsName.AddNewBrandClick, { source: 'checkUrl' }]"
        ></fid-connect-new-card>

        <ng-template #casinoCard>
          <fid-casino-card
            [casino]="casino"
            [fidSendAnalytics]="[
              ampEventsName.BrandBalanceItemClick,
              { brandDomain: casino.domain, isUserExist: true },
            ]"
            variant="flat-base"
          ></fid-casino-card>
        </ng-template>
      </ng-container>
    </ng-container>

    <ng-container *fidNotAuthenticated>
      <fid-casino-card [casino]="projectStaticData" variant="highlighted"></fid-casino-card>

      <ng-container *ngFor="let casino of casinos$ | async; trackBy: 'name' | trackByProp">
        <fid-connect-new-card
          *ngIf="casino.name === 'addNew'; else casinoCard"
          class="connected-casino-item"
          [fidRouterLink]="['casinos']"
          [fidSendAnalytics]="[ampEventsName.AddNewBrandClick, { source: 'checkUrl' }]"
        ></fid-connect-new-card>

        <ng-template #casinoCard>
          <fid-casino-card
            role="button"
            class="connected-casino-item"
            [casino]="casino"
            variant="flat-base"
          >
            <tui-svg src="fidConnectFilled"></tui-svg>
          </fid-casino-card>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
