<ng-container *ngIf="langCode$ | async as langCode">
  <button type="button" [appearance]="appearance" tuiIconButton fidOpenDialog="select-language">
    <img
      class="select-language--flag"
      [alt]="langCode"
      [ngSrc]="langCode | uppercase | tuiFlag"
      width="24"
      height="24"
    />
  </button>
</ng-container>
