<nav *transloco="let t" class="side-nav">
  <ul class="side-nav--list">
    <ng-container *ngFor="let navigationLink of navigationLinks; trackBy: 'text' | trackByProp">
      <li
        *ngIf="!!(user$ | async) ? navigationLink.action : navigationLink.action !== 'wallet'"
        class="side-nav--item"
      >
        <button
          appearance="whiteblock"
          class="side-nav--link"
          [attr.aria-label]="navigationLink.text"
          [routerLinkActive]="'_active'"
          [fidRouterLink]="navigationLink.action"
          [fidSendAnalytics]="[ampEventsName.SideBarClick, { source: navigationLink.text }]"
          (click)="close()"
          tuiButton
          size="l"
        >
          <span *ngIf="navigationLink.icon?.url as url" class="side-nav--text">
            <tui-icon class="icon" [icon]="url | cdnUrl" />

            {{ navigationLink.text }}
          </span>
        </button>
      </li>
    </ng-container>
  </ul>
</nav>
