import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BottomBarComponent } from 'shared/components/bottom-bar/bottom-bar.component';
import { HeaderSidebarComponent } from 'layout/header/components/header-sidebar/header-sidebar.component';

@Component({
  selector: 'fid-header',
  standalone: true,
  imports: [CommonModule, HeaderSidebarComponent, BottomBarComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {}
