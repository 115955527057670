import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ApiConfiguration } from 'core/fapi/api-configuration';
import { ResponseErrorCode } from 'features/auth/model/auth-error-codes.model';
import { catchError, Observable, throwError } from 'rxjs';
import { ErrorsStorageService } from 'core/base/services/errors-storage.service';
import { parseJson } from 'shared/utils/parse-json';
import { AlertPlaceholderTitle } from 'core/features/alerts/services/alert.service';

// handle 400
export function badRequestErrorInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const errorsService: ErrorsStorageService = inject(ErrorsStorageService);
  const apiConfiguration: ApiConfiguration = inject(ApiConfiguration);
  const isApiRequest: boolean = req.url.includes(apiConfiguration.rootUrl);

  return isApiRequest
    ? next(req).pipe(
        catchError(response => {
          if (response.status === ResponseErrorCode.BadRequest) {
            if (typeof response.error === 'string') {
              response = parseJson(response.error);
            }

            // Place for switching error message
            switch (response.error.exception.code) {
              case 'example':
                response = {
                  ...response,
                  response: {
                    error: {
                      exception: {
                        i18n: AlertPlaceholderTitle,
                      },
                    },
                  },
                };
                break;
            }

            errorsService.errors$.next(response);
          }

          return throwError(() => response);
        }),
      )
    : next(req);
}
