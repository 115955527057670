import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TuiButtonModule, TuiFlagPipeModule } from '@taiga-ui/core';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';
import { LocalizationService } from 'core/features/localization/services/localization.service';

@Component({
  selector: 'fid-language-selection',
  standalone: true,
  imports: [
    CommonModule,
    TuiButtonModule,
    OpenDialogDirective,
    TuiFlagPipeModule,
    NgOptimizedImage,
  ],
  templateUrl: './language-selection.component.html',
  styleUrl: './language-selection.component.scss',
})
export class LanguageSelectionComponent {
  @Input() public appearance: string = 'tertiary';

  private localizationServise = inject(LocalizationService);
  protected langCode$ = this.localizationServise.langCode$;
}
