import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

// function that returns `MarkedOptions` with renderer override
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.table = (header: string, body: string) => {
    if (body) {
      body = `<tbody>${body}</tbody>`;
    }
    return (
      "<div class='table-wrapper'>\n<table>\n<thead>\n" +
      header +
      '</thead>\n' +
      body +
      '</table>\n</div>'
    );
  };

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
  };
}
