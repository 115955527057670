import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiScrollbarModule, TuiSvgModule } from '@taiga-ui/core';
import { TrackByPropPipe } from 'core/base/utils/track-by-prop.pipe';
import { CasinoRepository } from 'core/data/repository/casino/casino.repository';
import { UserRepository } from 'core/data/repository/user/user.repository';
import { AuthenticatedDirective } from 'features/auth/directives/authenticated.directive';
import { NotAuthenticatedDirective } from 'features/auth/directives/not-authenticated.directive';
import { CasinoCardComponent } from '../casino-card/casino-card.component';
import { ConnectNewCardComponent } from '../connect-new-card/connect-new-card.component';
import { UserCardComponent } from 'features/user/components/user-card/user-card.component';
import { ProjectStaticData } from 'features/user/constants/user.constants';
import { RouterLinkDirective } from 'core/base/directives/router-link.directive';
import { AppUserService } from 'features/user/services/app-user.service';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';
import { WalletFullModel } from 'core/data/domain/wallet.model';
import { FunIdWallet } from 'features/wallet/constants/wallet.constants';
import { tap } from 'rxjs';
import { AmpEventsName } from 'shared/services/amplitude.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

@Component({
  selector: 'fid-connected-casino',
  standalone: true,
  imports: [
    TranslocoDirective,
    TuiScrollbarModule,
    NgIf,
    AsyncPipe,
    NgForOf,
    TrackByPropPipe,
    AuthenticatedDirective,
    NotAuthenticatedDirective,
    CasinoCardComponent,
    TuiSvgModule,
    ConnectNewCardComponent,
    UserCardComponent,
    RouterLinkDirective,
    OpenDialogDirective,
    SendAnalyticsDirective,
  ],
  templateUrl: './connected-casino.component.html',
  styleUrl: './connected-casino.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectedCasinoComponent {
  private readonly casinosRepository = inject(CasinoRepository);
  private readonly connectedRepository = inject(UserRepository);
  private readonly appUserService = inject(AppUserService);

  protected readonly casinos$ = this.casinosRepository.getList({ limit: '5' });
  protected readonly connected$ = this.connectedRepository.userConnectedProjectsExtended$;
  protected readonly user$ = this.appUserService.user$.pipe(
    tap(user => {
      this.wallet.balance = user?.balance || 0;
      this.wallet.currency = user?.currency || 'EUR';
    }),
  );

  protected readonly projectStaticData = ProjectStaticData;
  protected wallet: WalletFullModel = FunIdWallet;
  protected readonly ampEventsName = AmpEventsName;
}
