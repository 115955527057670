import { inject } from '@angular/core';
import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalizationService } from 'core/features/localization/services/localization.service';

export function apiInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const languageService = inject(LocalizationService);
  const request =
    req.method !== 'GET'
      ? req
      : req.clone({
          setParams: {
            locale: languageService.getActiveLangCode(),
          },
        });

  return next(request);
}
