<ng-container *ngIf="breakpoints$ | async as breakpoints">
  <ng-container *transloco="let t">
    <div *ngFor="let payment of paymentsList; trackBy: 'text' | trackByProp" class="payment">
      <img
        *ngIf="payment.icon?.url as url"
        [alt]="payment.icon?.alternativeText"
        [ngSrc]="url | cdnUrl"
        [width]="payment.icon?.width"
        [height]="payment.icon?.height"
      />
    </div>
  </ng-container>
</ng-container>
