<div
  *ngIf="avatarRef.avatar$ | async as avatar"
  class="profile-card-background"
  [attr.style]="'--fid-card-bg: ' + avatar.backgroundColor"
></div>

<fid-profile-avatar
  #avatarRef
  class="profile-card-media"
  [code]="user.avatar"
  [withBackground]="true"
  size="small"
></fid-profile-avatar>

<div *transloco="let t" class="profile-card-details">
  <span class="profile-card-label">
    {{ t('global.profile.title') }}
  </span>
  <span class="profile-card-value">
    {{ user.name }}
  </span>
</div>
