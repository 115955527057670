<ng-container *transloco="let t">
  <fid-xmas-background [density]="0.5"></fid-xmas-background>

  <a class="header-logo" fidRouterLink="discovery">
    <div class="header-logo--animation">
      <img
        alt="Logo"
        class="header-logo--img"
        [attr.srcset]="'/assets/images/logo.png, /assets/images/logo@2x.png 2x'"
        [ngSrc]="'/assets/images/logo.png'"
        height="80"
        width="80"
      />
    </div>

    <p class="header-logo--name">{{ t('global.app.name') }}</p>

    <button
      *ngIf="!isFlipped"
      type="button"
      class="header-logo-button"
      [attr.aria-label]="t('global.btn.flipElement')"
      [appearance]="'tertiary'"
      [shape]="'rounded'"
      (click)="flip()"
      tuiIconButton
      size="s"
    >
      <tui-svg [src]="'fidQR'"></tui-svg>
    </button>
  </a>

  <div *ngIf="breakpoints$ | async as breakpoints" class="header-logo-qr-code">
    {{ t('global.title.scanQR') }}

    <fid-qr-image class="header-logo-qr-code--image" [size]="104"></fid-qr-image>

    <button
      type="button"
      class="header-logo-button"
      [attr.aria-label]="t('global.btn.flipElement')"
      [appearance]="'secondary'"
      [shape]="'rounded'"
      (click)="flip()"
      tuiIconButton
      size="s"
    >
      <tui-svg [src]="'fidQR'"></tui-svg>
    </button>
  </div>
</ng-container>
