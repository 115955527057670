<nav class="desktop-sitemap">
  <ul class="desktop-sitemap--lists">
    <li *ngFor="let group of navigationLinksGroup; trackBy: 'text' | trackByProp">
      <h4 class="desktop-sitemap--title">{{ group.text }}:</h4>

      <ul class="desktop-sitemap--list">
        <li *ngFor="let navigationLink of group.navigationItems; trackBy: 'text' | trackByProp">
          <a
            *ngIf="!(navigationLink.action | fidStartsWith: 'http')"
            [fidRouterLink]="navigationLink.action"
            tuiLink
          >
            {{ navigationLink.text }}
          </a>

          <a
            *ngIf="navigationLink.action | fidStartsWith: 'http'"
            [href]="navigationLink.action"
            tuiLink
          >
            {{ navigationLink.text }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
