import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { TranslocoLoaderData } from '@ngneat/transloco/lib/transloco.loader';
import { TranslationListResponse } from 'core/capi/models/translation-list-response';
import { ApiConfiguration } from 'core/capi/api-configuration';

export const TRANSLOCO_GLOBAL_SCOPE = 'global';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http: HttpClient = inject(HttpClient);
  private config = inject(ApiConfiguration);

  public getTranslation(lang: string, data?: TranslocoLoaderData): Observable<Translation> {
    const scope = data?.scope || TRANSLOCO_GLOBAL_SCOPE;

    return this.http
      .get<TranslationListResponse>(
        `${this.config.rootUrl}/translations/?populate[translations]=*&populate[scope]=*&filters[scope][name][$eq]=${scope}`,
      )
      .pipe(map(transformAPItoTranslations));
  }
}

function transformAPItoTranslations(resp: TranslationListResponse): Translation {
  if (resp.data && resp.data.length > 0) {
    const translations = resp.data[0].attributes?.translations?.map(tr => ({
      key: tr.key,
      value: tr.value,
    })) as {
      key: string;
      value: string;
    }[];
    const result: Translation = {};

    translations?.forEach(({ key, value }) => {
      key.split('.').reduce((dest, keyPart, index, arr) => {
        if (Object.prototype.hasOwnProperty.call(dest, keyPart)) {
          return dest[keyPart];
        } else {
          if (index === arr.length - 1) {
            dest[keyPart] = value;
          } else {
            dest[keyPart] = {};
          }
          return dest[keyPart];
        }
      }, result);
    });
    return result;
  }

  return {};
}
