<ng-container *ngIf="widget$ | async as widget; else loader">
  <ng-container *transloco="let t">
    <fid-user-card
      [user]="widget.user"
      [dialogConfig]="{ data: { casino: projectStaticData, wallet } }"
      [fidSendAnalytics]="[ampEventsName.FunIdBalanceItemClick]"
      fidOpenDialog="cashier"
    ></fid-user-card>

    <div class="header-logged-dashboard-heading">
      <span class="header-logged-dashboard-title">
        {{ t('casino.connected.title') }}
      </span>

      <a
        appearance="accent"
        type="button"
        fidRouterLink="casinos"
        tuiIconButton
        size="m"
        shape="rounded"
      >
        <tui-svg src="fidBars"></tui-svg>
      </a>
    </div>

    <ng-container *ngIf="widget.connected as connected">
      <fid-casino-card *ngFor="let casino of connected" [casino]="casino" [variant]="'base-logged'">
        <button
          appearance="accent"
          type="button"
          [dialogConfig]="{ data: { casino, balance: casino.balance } }"
          tuiIconButton
          size="m"
          shape="rounded"
          fidOpenDialog="cashier"
        >
          {{ casino.balance }}
          <tui-svg src="fidDeposit"></tui-svg>
        </button>
      </fid-casino-card>
    </ng-container>

    <ng-container *ngIf="widget.recommended as recommended">
      <fid-casino-card
        *ngFor="let casino of recommended"
        [casino]="casino"
        [labelKey]="t('casino.recommended')"
      >
        <button
          appearance="accent"
          type="button"
          [dialogConfig]="{ data: { casino } }"
          tuiIconButton
          size="m"
          shape="rounded"
          fidOpenDialog="cashier"
        >
          <tui-svg src="fidLink"></tui-svg>
        </button>
      </fid-casino-card>
    </ng-container>

    <a
      class="header-logged-dashboard-more"
      [fidRouterLink]="['wallet']"
      tuiLink
      icon="fidChevronRight"
      >{{ t('global.btn.showMore') }}</a
    >
  </ng-container>
</ng-container>

<ng-template #loader>
  <fid-loader height="270"></fid-loader>
</ng-template>
