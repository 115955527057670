import { DestroyRef, inject, Injectable } from '@angular/core';
import { FidQueryParam } from 'core/base/constants/query-params.constants';
import { ApiService } from 'core/fapi/services/api.service';
import { combineLatest, map, Observable, of, take, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs/operators';
import { GuestParams, Integration } from 'features/guest/model/guest.model';
import { LOCAL_STORAGE } from '@ng-web-apis/common';

@Injectable({ providedIn: 'root' })
export class GuestService {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly apiService = inject(ApiService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly storage: Storage = inject(LOCAL_STORAGE);

  private readonly getStorageUuid? = this.storage.getItem('uuid') as string;
  private readonly uuidStorage$ = of(this.getStorageUuid);

  private readonly getQuery$ = this.activatedRoute.queryParams.pipe(
    filter(queryParams => queryParams[FidQueryParam.MId] || queryParams[FidQueryParam.FluId]),
    takeUntilDestroyed(this.destroyRef),
  );

  private readonly getUuid$ = this.getStorageUuid
    ? this.uuidStorage$
    : this.apiService.guestControllerRegister().pipe(map(response => response.data.uuid));

  private readonly buildData$: Observable<GuestParams> = combineLatest({
    payload: this.getQuery$,
    uuid: this.getUuid$,
  });

  public register(): void {
    if (!this.getStorageUuid) {
      this.buildData$
        .pipe(
          take(1),
          tap(data => {
            if (data.uuid && data.payload.mid && data.payload.fluid) {
              this.apiService
                .guestControllerProfileIntegration({
                  integration: Integration.Appsflyer,
                  body: { payload: { ...data.payload } },
                })
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe();

              this.storage.setItem('uuid', data.uuid);
            }
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe();
    }
  }
}
