import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export abstract class RendererService {
  private rendererFactory: RendererFactory2 = inject(RendererFactory2);

  public renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);
}
