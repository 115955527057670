import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-web-apis/common';

// add Uuid token to requests
export function guestInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const storage: Storage = inject(LOCAL_STORAGE);
  const uuid = storage.getItem('uuid');

  const request = uuid
    ? req.clone({
        headers: req.headers.set('x-guest-uuid', `${uuid}`),
      })
    : req;

  return next(request);
}
