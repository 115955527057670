import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageValidation } from 'core/features/localization/model/localization.model';

@Injectable({
  providedIn: 'root',
})
export class LanguageValidationService implements LanguageValidation {
  private transLoco: TranslocoService = inject(TranslocoService);

  public isValid(code: string): boolean {
    return this.transLoco.isLang(code);
  }
}
