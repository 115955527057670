import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';

export const dataLayerKey = 'dataLayer';

declare global {
  interface Window {
    [dataLayerKey]?: Record<string, unknown>[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class GtmEventService {
  private readonly window: Window = inject(WINDOW);

  public pushStartEvent(): void {
    if (!Array.isArray(this.window[dataLayerKey])) {
      this.window[dataLayerKey] = [];
    }

    this.window[dataLayerKey]?.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  }
}
