import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, DestroyRef, EnvironmentProviders, inject, Provider } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { unauthorizedErrorInterceptor } from 'features/auth/interceptors/unauthorized-error.interceptor';
import { authInterceptor } from 'features/auth/interceptors/auth.interceptor';
import { FunIdAuthStorageKey } from 'features/auth/model/auth.model';
import { AppAuthService } from 'features/auth/services/app-auth.service';
import { delay, fromEvent, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { badRequestErrorInterceptor } from 'features/auth/interceptors/bad-request-error.interceptor';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export const provideAuth = (): Array<Provider | EnvironmentProviders> => [
  {
    provide: APP_INITIALIZER,
    useFactory: () => {
      const window: Window = inject(WINDOW);
      const appAuth: AppAuthService = inject(AppAuthService);
      const destroyRef: DestroyRef = inject(DestroyRef);

      return () =>
        fromEvent(window, 'storage')
          .pipe(
            filter((event: unknown): event is StorageEvent => event instanceof StorageEvent),
            filter(({ key }: StorageEvent) => key === FunIdAuthStorageKey.AuthToken),
            delay(500),
            tap(event => {
              if (
                !event.storageArea?.getItem(FunIdAuthStorageKey.AuthToken) &&
                !window.location.href.includes('discovery')
              ) {
                appAuth.logout();
              } else {
                window.location.reload();
              }
            }),
            takeUntilDestroyed(destroyRef),
          )
          .subscribe();
    },
    multi: true,
  },
  provideHttpClient(
    withInterceptors([authInterceptor, unauthorizedErrorInterceptor, badRequestErrorInterceptor]),
  ),
];
