import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoDirective } from '@ngneat/transloco';
import { TuiButtonModule, TuiLinkModule, TuiSvgModule } from '@taiga-ui/core';
import { RouterLinkDirective } from 'core/base/directives/router-link.directive';
import { UserRepository } from 'core/data/repository/user/user.repository';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';
import { CasinoCardComponent } from 'features/casino/casino-card/casino-card.component';
import { combineLatest, map, tap } from 'rxjs';
import { UserCardComponent } from 'features/user/components/user-card/user-card.component';
import { AppUserService } from 'features/user/services/app-user.service';
import { UserInfoResponse } from 'core/fapi/models/user-info-response';
import { ProjectStaticData } from 'features/user/constants/user.constants';
import { LoaderComponent } from 'shared/components/loader/loader.component';
import { WalletFullModel } from 'core/data/domain/wallet.model';
import { FunIdWallet } from 'features/wallet/constants/wallet.constants';
import { AmpEventsName } from 'shared/services/amplitude.service';
import { SendAnalyticsDirective } from 'shared/directives/send-analytics.directive';

@Component({
  selector: 'fid-header-logged-dashboard',
  standalone: true,
  imports: [
    AsyncPipe,
    NgFor,
    NgIf,
    OpenDialogDirective,
    TranslocoDirective,
    TuiButtonModule,
    TuiSvgModule,
    CasinoCardComponent,
    RouterLinkDirective,
    TuiLinkModule,
    UserCardComponent,
    LoaderComponent,
    SendAnalyticsDirective,
  ],
  templateUrl: './header-logged-dashboard.component.html',
  styleUrl: './header-logged-dashboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLoggedDashboardComponent {
  private readonly userRepository: UserRepository = inject(UserRepository);
  private readonly appUserService: AppUserService = inject(AppUserService);

  protected readonly projectStaticData = ProjectStaticData;

  protected readonly connected$ = this.userRepository.userConnectedProjects$;
  protected readonly recommended$ = this.userRepository.getUserNotConnectedProjects();
  protected readonly widget$ = combineLatest([
    this.connected$,
    this.recommended$,
    this.appUserService.user$,
  ]).pipe(
    map(([connected, recommended, user]) => ({
      connected: connected.slice(0, 2),
      recommended: recommended.slice(0, Math.max(0, 2 - connected.length)),
      user: user as UserInfoResponse,
    })),
    tap(widget => {
      if (widget.user) {
        this.wallet.balance = widget.user.balance || 0;
        this.wallet.currency = widget.user.currency || 'EUR';
      }
    }),
  );

  protected wallet: WalletFullModel = FunIdWallet;
  protected readonly ampEventsName = AmpEventsName;
}
