import { EnvironmentModel } from 'core/base/model/environment.model';

export const environment: EnvironmentModel = {
  basePath: '//api.funid.com',
  cApiPath: '//content.funid.com/api',
  cApiInternalPath: '//content.funid.com/api',
  fApiDomain: '//api.funid.com/',
  fApiPath: '//api.funid.com/api',
  fApiInternalPath: '//api.funid.com/api',
  cdnUrl: 'content.funid.com',
  gtmId: 'GTM-MKJN3K6V',
  gamesBrandSource: 'wazamba.com',
  amplitudeKey: 'c592e6d3f273364ae7a2a4c5c8dba4b6',
  firebaseConfig: {
    apiKey: 'AIzaSyAuE4a8D_lPFoCg7BmyhqyWede4w2WzyV0',
    authDomain: 'funid-prod.firebaseapp.com',
    projectId: 'funid-prod',
    storageBucket: 'funid-prod.appspot.com',
    messagingSenderId: '141423995904',
    appId: '1:141423995904:web:00cc293f34a28826523ce5',
    measurementId: 'G-F164B4V3GT',
  },
};
