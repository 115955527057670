import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  Input,
} from '@angular/core';
import {
  AsyncPipe,
  CurrencyPipe,
  NgClass,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
} from '@angular/common';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { CdnUrlPipe } from 'core/base/utils/cdn-url.pipe';
import { TranslocoDirective } from '@ngneat/transloco';
import { ProjectStaticData } from 'features/user/constants/user.constants';
import { BreakpointsService } from 'shared/services/breakpoints.service';
import { UserInfoResponse } from 'core/fapi/models/user-info-response';
import { OpenDialogDirective } from 'core/features/dialog/directives/open-dialog.directive';
import { SocketIoService } from 'core/base/services/websocket.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WalletFullModel } from 'core/data/domain/wallet.model';

type Size = 'small' | 'large';

@Component({
  selector: 'fid-user-card',
  standalone: true,
  imports: [
    NgIf,
    TuiButtonModule,
    TuiSvgModule,
    CdnUrlPipe,
    CurrencyPipe,
    NgOptimizedImage,
    TranslocoDirective,
    AsyncPipe,
    NgClass,
    NgTemplateOutlet,
    OpenDialogDirective,
  ],
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCardComponent {
  private readonly breakpointsService = inject(BreakpointsService);
  private readonly wsIo: SocketIoService = inject(SocketIoService);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  @Input({ required: true }) public user!: UserInfoResponse;
  @Input() public wallet?: WalletFullModel;
  @Input() @HostBinding('class') public size: Size = 'small';

  protected readonly projectStaticData = ProjectStaticData;
  protected readonly breakpoints$ = this.breakpointsService.selectAllBreakpointsObservable();
  protected readonly imageSizeMap: Record<Size, number> = {
    small: 40,
    large: 60,
  };

  constructor() {
    this.wsIo.getSocketAppBalance().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }
}
