import { EnvironmentModel } from 'core/base/model/environment.model';

export const environment: EnvironmentModel = {
  basePath: '//api-dev.funid.com',
  cApiPath: '//cms-dev.funid.com/api',
  cApiInternalPath: '//cms-dev.funid.com/api',
  fApiDomain: '//api-dev.funid.com/',
  fApiPath: '//api-dev.funid.com/api',
  fApiInternalPath: '//api-dev.funid.com/api',
  cdnUrl: 'cms-dev.funid.com',
  gtmId: 'GTM-MKJN3K6V',
  gamesBrandSource: 'wazamba.com',
  amplitudeKey: '73a5856d47fc79b17adbb193392903d4',
  firebaseConfig: {
    apiKey: 'AIzaSyD6i5VKVkmGQHGtlG6Q9R9WbbAP3ZuB0qI',
    authDomain: 'casino-id.firebaseapp.com',
    databaseURL: 'https://casino-id.firebaseio.com',
    projectId: 'casino-id',
    storageBucket: 'casino-id.appspot.com',
    messagingSenderId: '235026236724',
    appId: '1:235026236724:web:7479f903506cb1c32a1161',
    measurementId: 'G-68GVPVZ9F4',
  },
};
