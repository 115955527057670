import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { environment } from '@environments/environment.development';
import { GtmEventService } from 'features/gtm/services/gtm-event.service';
import { GtmScriptService } from 'features/gtm/services/gtm-script.service';

const gtmFactory = () => {
  const scriptService: GtmScriptService = inject(GtmScriptService);
  const eventService: GtmEventService = inject(GtmEventService);

  return (): void => {
    if (!environment.gtmId) {
      return;
    }

    const gtmScript: HTMLScriptElement = scriptService.create({
      src: `https://www.googletagmanager.com/gtm.js?id=${environment.gtmId}`,
    });

    eventService.pushStartEvent();
    scriptService.append(gtmScript);
  };
};

export const provideGtm = (): Provider => ({
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: gtmFactory,
});
