export enum ResponseErrorCode {
  Unauthorized = 401,
  BadRequest = 400,
}

export enum AuthErrorExceptionCode {
  NotFound = 'ONETOKEN.NOT_FOUND',
  CodeExpired = 'ONETOKEN.EXPIRED',
  ProjectAuthenticationFailed = 'PROJECT.AUTHENTICATION_FAILED',
  ProjectDuplicateUser = 'PROJECT.DUPLICATE_USER',
}
