<tui-accordion class="accordion" [rounded]="false">
  <tui-accordion-item
    *ngFor="let group of navigationLinksGroup; trackBy: 'text' | trackByProp"
    [showArrow]="false"
  >
    <span class="accordion--title">
      {{ group.text }}
      <tui-svg class="accordion--arrow" [src]="'fidChevronDown'"></tui-svg>
    </span>
    <ul tuiAccordionItemContent>
      <li *ngFor="let navigationLink of group.navigationItems; trackBy: 'text' | trackByProp">
        <a
          *ngIf="!(navigationLink.action | fidStartsWith: 'http')"
          class="accordion--link"
          [fidRouterLink]="navigationLink.action"
          tuiLink
        >
          {{ navigationLink.text }}
        </a>

        <a
          *ngIf="navigationLink.action | fidStartsWith: 'http'"
          class="accordion--link"
          [href]="navigationLink.action"
          tuiLink
        >
          {{ navigationLink.text }}
        </a>
      </li>
    </ul>
  </tui-accordion-item>
</tui-accordion>
