<ng-container *ngFor="let license of licenses; trackBy: 'text' | trackByProp">
  <a
    *ngIf="license.action; else cerfiticationIcon"
    [href]="license.action"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    <ng-container *ngTemplateOutlet="cerfiticationIcon"></ng-container>
  </a>

  <ng-template #cerfiticationIcon>
    <img
      *ngIf="license.icon?.url as url"
      class="footer-license"
      [alt]="license.icon?.alternativeText"
      [ngSrc]="url | cdnUrl"
      [width]="license.icon?.width"
      [height]="license.icon?.height"
    />
  </ng-template>
</ng-container>
